<template>
  <Catalog @new="newEntity" v-model="entity" :validate="validate" name="Impuestos" :rules="rules" :headers="headers" :inputs="inputs" />
</template>

<script>
import Catalog from "../../../components/templates/BasicCatalog.vue";
import { Tax } from "../../../models/cxp/Tax";
import { AccountingLedgerAccount } from "../../../models/contabilidad/AccountingLedgerAccount";
import Session from "../../../mixins/sessionMixin";
import { HeaderGrid, Rule, CatalogInput, ValidateForm } from '../../../utilities/General';

export default {
  data() {
    return {
      entity: new Tax(),
      inputs: [
        new CatalogInput("Nombre", "name", "text", { cols: 6 }),
        new CatalogInput("Clave", "key_name", "text",  { cols: 6 }),
        new CatalogInput("Tipo", "type", "Dropdown", { cols: 4, props: { optionLabel: "name", optionValue: "name", options: [
          { name: "IVA Acreditable" },
          { name: "IVA Trasladado" },
          { name: "ISR Retenido" },
          { name: "IVA Retenido" },
          { name: "IEPS Acreditable" }
        ] }}),
        new CatalogInput("Cuenta Contable", "id_ledger_account", "Dropdown", { cols: 4, props: { filter: true, optionLabel : "name", optionValue : "id", 
        options: [{id: 0, name: '220302'}]} }),
        new CatalogInput("Tasa", "rate", "InputNumber", { cols: 2, props: { minFractionDigits:2, maxFractionDigits:2 } }),
        new CatalogInput("Vigente", "active", "switch", { cols: 2 }),
      ],
      rules: [
        new Rule({ name: "name" }),
        new Rule({ name: "key_name" })
      ],
      headers: [
        new HeaderGrid("Nombre", "name"),
        new HeaderGrid("Clave", "key_name"),
        new HeaderGrid("Tasa", "rate", { type: "percentage" })
      ],
      validate: new ValidateForm()
    }
  },
  mixins: [Session],
  components: { Catalog },
  methods: {
    newEntity() {
      this.entity = new Tax(this.session); 
      this.validate.clear();
    },
  },
  async created() {
    this.validate = new ValidateForm(this.rules);
    this.entity = new Tax(this.session); 
    this.inputs[3].props.options = await new AccountingLedgerAccount(this.session).all();
    this.inputs[3].props.options = this.inputs[3].props.options.map(option => {
      return {
        id: option.id,
        name: option.id_key + ' - ' + option.name
      }
    })
  }
}
</script>

<style>

</style>